import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"
import useInterval from "../hooks/useInterval"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import * as styles from "../stylesheets/components/testimonials.module.scss"

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicTestimonials {
        data {
          testimonial {
            body {
              text
            }
            source {
              text
            }
          }
          title {
            text
          }
        }
      }
    }
  `).prismicTestimonials.data

  const [selectedTestimonial, setSelectedTestimonial] = useState(0)
  const [disableTimer, setDisableTimer] = useState(false)

  useInterval(() => {
    if (!disableTimer) {
      if (selectedTestimonial < data.testimonial.length - 1) {
        setSelectedTestimonial(selectedTestimonial + 1)
      } else {
        setSelectedTestimonial(0)
      }
    }
  }, 8000)

  const handleClick = (direction) => {
    setDisableTimer(true)
    if (direction === "left") {
      if (selectedTestimonial !== 0) {
        setSelectedTestimonial(selectedTestimonial - 1)
      } else {
        setSelectedTestimonial(data.testimonial.length - 1)
      }
    } else {
      if (selectedTestimonial !== data.testimonial.length - 1) {
        setSelectedTestimonial(selectedTestimonial + 1)
      } else {
        setSelectedTestimonial(0)
      }
    }
  }

  const testimonials = data.testimonial.map((item, index, array) => {
    //console.log(index === selectedTestimonial)
    const hidden = index === selectedTestimonial ? false : true
    return (
      <div
        className={`${styles.testimonialContainer} ${hidden && styles.hidden}`}
        key={index}
      >
        <div className={styles.textBox}>
          <FaQuoteLeft
            style={{ alignSelf: "flex-start", marginBottom: "4px" }}
            className={styles.quote}
          />
          <p className={styles.text}>{item.body.text}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <FaQuoteRight
              style={{ alignSelf: "flex-end", marginTop: "4px" }}
              className={styles.quote}
            />
          </div>

          <p className={styles.source}>{item.source.text}</p>
        </div>
      </div>
    )
  })

  const selectDots = data.testimonial.map((item, index) => {
    return (
      <div
        className={
          index === selectedTestimonial
            ? `${styles.selectDot} ${styles.selected}`
            : styles.selectDot
        }
        key={index}
      ></div>
    )
  })

  return (
    <div className={styles.container}>
      <div>{testimonials}</div>
      <div className={styles.selectDotContainer}>
        <MdChevronLeft
          className={styles.lrIcon}
          onClick={(e) => handleClick("left")}
        />
        {selectDots}
        <MdChevronRight
          className={styles.lrIcon}
          onClick={(e) => handleClick("right")}
        />
      </div>
    </div>
  )
}

export default Testimonials

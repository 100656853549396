// extracted by mini-css-extract-plugin
export var container = "spheres-module--container--ca283";
export var description = "spheres-module--description--ff8a7";
export var descriptionBody = "spheres-module--description-body--ce369";
export var expandedDescription = "spheres-module--expanded-description--ae28b";
export var expandedDescriptionTitle = "spheres-module--expanded-description-title--9cb5b";
export var fadeIn = "spheres-module--fadeIn--3f10e";
export var hidden = "spheres-module--hidden--967c5";
export var list = "spheres-module--list--f749b";
export var lrIcon = "spheres-module--lr-icon--1ca13";
export var sphere = "spheres-module--sphere--2a1bf";
export var sphereCircle = "spheres-module--sphere-circle--cdaca";
export var sphereCircleActive = "spheres-module--sphere-circle-active--cbac1";
export var sphereGroup = "spheres-module--sphere-group--1ccb1";
export var sphereGroup1 = "spheres-module--sphere-group1--7685c";
export var sphereGroup2 = "spheres-module--sphere-group2--2c183";
export var sphereGroupWide = "spheres-module--sphere-group-wide--30bc5";
export var sphereImage = "spheres-module--sphere-image--d8cbc";
export var sphereImageActive = "spheres-module--sphere-image-active--d6f82";
export var sphereImageContainer = "spheres-module--sphere-image-container--dcbe8";
export var sphereTitle = "spheres-module--sphere-title--d1310";
export var sphereTitleActive = "spheres-module--sphere-title-active--60990";
// extracted by mini-css-extract-plugin
export var container = "crossPlatform-module--container--66fa9";
export var contentContainer = "crossPlatform-module--content-container--67b4b";
export var description = "crossPlatform-module--description--b26bc";
export var icon = "crossPlatform-module--icon--8eb54";
export var iconContainer = "crossPlatform-module--icon-container--54d8a";
export var iconMain = "crossPlatform-module--icon-main--f70ac";
export var logo = "crossPlatform-module--logo--9c029";
export var logoContainer = "crossPlatform-module--logo-container--90e38";
export var services = "crossPlatform-module--services--608fd";
export var servicesDescription = "crossPlatform-module--services-description--566fa";
export var title = "crossPlatform-module--title--36d80";
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "./GatsbyLink"
import * as styles from "../stylesheets/components/crossPlatform.module.scss"

const CrossPlatform = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicCrossPlatform {
        data {
          company_logos {
            image {
              alt
              url
            }
          }
          description {
            text
            richText
            html
          }
          listed_services {
            service {
              html
              richText
              text
            }
          }
          main_images {
            image {
              alt
              url
            }
          }
          services_description {
            html
            richText
            text
          }
          title {
            html
            richText
            text
          }
        }
      }
    }
  `).prismicCrossPlatform.data

  const images = data.main_images.map((image, index) => {
    return (
      <img
        src={image.image.url}
        alt={image.image.alt}
        className={`${styles.icon} ${index === 1 && styles.iconMain}`}
        key={index}
      />
    )
  })

  const logos = data.company_logos.map((logo, index) => {
    return (
      <img
        src={logo.image.url}
        alt={logo.image.alt}
        className={styles.logo}
        key={index}
      />
    )
  })

  const services = data.listed_services.map((service, index) => {
    return (
      <li styles={{ color: "white" }} key={index}>
        <RichText
          render={service.service.richText}
          serializeHyperlink={GatsbyLink}
        />
      </li>
    )
  })

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.iconContainer}>{images}</div>
        <h2 className={styles.title}>{data.title.text}</h2>
        <div className={styles.description}>
          <RichText
            render={data.description.richText}
            serializeHyperlink={GatsbyLink}
          />
        </div>
        <div className={styles.servicesDescription}>
          <RichText
            render={data.services_description.richText}
            serializeHyperlink={GatsbyLink}
          />
        </div>
        <ul className={styles.services}>{services}</ul>
        <div className={styles.logoContainer}>{logos}</div>
      </div>
    </div>
  )
}

export default CrossPlatform

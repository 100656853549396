import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "./GatsbyLink"
import * as styles from "../stylesheets/components/bottomCTA.module.scss"

const BottomCTA = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicBottomCta {
        data {
          cta {
            text
          }
          description {
            richText
          }
          title {
            text
          }
        }
      }
    }
  `).prismicBottomCta.data

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{data.title.text}</h2>
      <div className={styles.description}>
        <RichText
          render={data.description.richText}
          serializeHyperlink={GatsbyLink}
        />
      </div>
      <Link to="/free-consultation" className={styles.ctaContainer}>
        <button className={styles.button}>{data.cta.text}</button>
      </Link>
    </div>
  )
}

export default BottomCTA

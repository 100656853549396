import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "./GatsbyLink"
import * as styles from "../stylesheets/components/spheres.module.scss"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"

const Spheres = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicFiveSpheres {
        data {
          bg_image {
            alt
            url
          }
          description {
            richText
          }
          title {
            text
          }
          sphere {
            description1 {
              richText
            }
            bullets {
              richText
            }
            icon {
              alt
              url
            }
            name {
              text
            }
          }
        }
      }
    }
  `).prismicFiveSpheres.data

  const [selectedSphere, setSelectedSphere] = useState(0)

  const handleClick = (sphere) => {
    setSelectedSphere(sphere)
  }

  const handleArrowClick = (direction) => {
    if (direction === 1) {
      selectedSphere < spheres.length - 1
        ? setSelectedSphere(selectedSphere + 1)
        : setSelectedSphere(0)
    } else {
      selectedSphere > 0
        ? setSelectedSphere(selectedSphere - 1)
        : setSelectedSphere(spheres.length - 1)
    }
  }

  const spheres = data.sphere.map((sphere, index) => {
    return (
      <div
        className={styles.sphere}
        key={index}
        onClick={(e) => handleClick(index)}
      >
        <div className={styles.sphereImageContainer}>
          <div
            className={`${styles.sphereCircle} ${
              index === selectedSphere && styles.sphereCircleActive
            }`}
          >
            <img
              src={sphere.icon.url}
              alt={sphere.icon.alt}
              className={`${styles.sphereImage} ${
                index === selectedSphere && styles.sphereImageActive
              }`}
            />
          </div>
        </div>
        <div>
          <h3
            className={`${styles.sphereTitle} ${
              index === selectedSphere && styles.sphereTitleActive
            }`}
          >
            {sphere.name.text.toUpperCase()}
          </h3>
        </div>
      </div>
    )
  })

  const sphereGroup1 = (
    <div className={styles.sphereGroup1}>
      {spheres[0]}
      {spheres[1]}
    </div>
  )

  const sphereGroup2 = (
    <div className={styles.sphereGroup1}>
      {spheres[2]}
      {spheres[3]}
      {spheres[4]}
    </div>
  )

  const description = data.sphere.map((item, index) => {
    const hidden = index === selectedSphere ? false : true
    return (
      <div className={`${hidden && styles.hidden}`} key={index}>
        <div className={styles.descriptionBody}>
          <RichText
            render={item.description1.richText}
            serializeHyperlink={GatsbyLink}
          />
        </div>
        <ul className={styles.list}>
          {item.bullets.richText.map((bullet, index) => {
            return <li key={index}>{bullet.text}</li>
          })}
        </ul>
      </div>
    )
  })

  return (
    <div className={styles.container}>
      <h2>{data.title.text}</h2>
      <div className={styles.description}>
        <RichText
          render={data.description.richText}
          serializeHyperlink={GatsbyLink}
        />
      </div>
      <div className={styles.sphereGroup}>
        <MdChevronLeft
          className={styles.lrIcon}
          onClick={(e) => handleArrowClick(0)}
        />
        <div>
          {sphereGroup1}
          {sphereGroup2}
        </div>
        <MdChevronRight
          className={styles.lrIcon}
          onClick={(e) => handleArrowClick(1)}
        />
      </div>
      <div className={styles.sphereGroupWide}>
        <MdChevronLeft
          className={styles.lrIcon}
          onClick={(e) => handleArrowClick(0)}
        />
        {spheres}
        <MdChevronRight
          className={styles.lrIcon}
          onClick={(e) => handleArrowClick(1)}
        />
      </div>

      <div>
        <div className={styles.expandedDescription}>{description}</div>
      </div>
    </div>
  )
}

export default Spheres

// extracted by mini-css-extract-plugin
export var container = "testimonials-module--container--b8660";
export var down = "testimonials-module--down--0abe7";
export var downIcon = "testimonials-module--down-icon--d5086";
export var fadeIn = "testimonials-module--fadeIn--9b569";
export var hidden = "testimonials-module--hidden--60340";
export var lrIcon = "testimonials-module--lr-icon--901e1";
export var quote = "testimonials-module--quote--d3394";
export var selectDot = "testimonials-module--select-dot--f9653";
export var selectDotContainer = "testimonials-module--select-dot-container--b9522";
export var selected = "testimonials-module--selected--25dce";
export var source = "testimonials-module--source--04754";
export var testimonialContainer = "testimonials-module--testimonial-container--6b418";
export var text = "testimonials-module--text--0cc09";
export var textBox = "testimonials-module--text-box--b4c10";
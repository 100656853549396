import React from "react"
import Layout from "../components/layouts/Layout.js"
import Hero from "../components/Hero.js"
import Testimonials from "../components/Testimonials.js"
import CrossPlatform from "../components/CrossPlatform"
import Spheres from "../components/Spheres"
import Plans from "../components/Plans"
import BottomCTA from "../components/BottomCTA"
import * as styles from "../stylesheets/pages/index.module.scss"

const Index = () => {
  return (
    <Layout canonical="https://techcollective.com">
      <Hero />
      <div className={styles.contentContainer}>
        <Testimonials />
        <CrossPlatform />
        <Spheres />
        <Plans />
        <BottomCTA />
      </div>
    </Layout>
  )
}

export default Index

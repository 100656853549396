// extracted by mini-css-extract-plugin
export var bottomText = "plans-module--bottom-text--cd70b";
export var container = "plans-module--container--28452";
export var description = "plans-module--description--68049";
export var fadeIn = "plans-module--fadeIn--ba1be";
export var hidden = "plans-module--hidden--f5768";
export var icon = "plans-module--icon--9a1cb";
export var plan = "plans-module--plan--0616e";
export var planContainer = "plans-module--plan-container--409f4";
export var planContent = "plans-module--plan-content--0e13e";
export var planName = "plans-module--plan-name--20637";
export var planPrice = "plans-module--plan-price--f0b5a";
export var price = "plans-module--price--de8f6";
export var selectContainer = "plans-module--selectContainer--5d425";
export var selected = "plans-module--selected--21bb7";
export var sfText = "plans-module--sf-text--eeea1";
export var startingAt = "plans-module--starting-at--d1014";
export var tagline = "plans-module--tagline--8f365";
export var title = "plans-module--title--aa1b9";
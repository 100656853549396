import React, { useState } from "react"
import * as styles from "../stylesheets/components/plans.module.scss"
import { graphql, useStaticQuery, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "./GatsbyLink"

const Plans = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicServicePlans {
        data {
          plan {
            bullets {
              richText
            }
            icon {
              url
              alt
            }
            name {
              text
            }
            price {
              text
            }
            tagline {
              text
            }
          }
          title {
            text
          }
          description {
            richText
          }
          bottom_text {
            richText
          }
        }
      }
    }
  `).prismicServicePlans.data

  const [plan, setPlan] = useState("Boston")

  const handleClick = (item) => {
    setPlan(item)
  }

  const bostonPlans = data.plan.map((plan, idx) => {
    return (
      <div className={styles.plan} key={idx}>
        <h3 className={styles.planName}>{plan.name.text.toUpperCase()}</h3>
        <h5 className={styles.tagline}>{plan.tagline.text}</h5>
        <img src={plan.icon.url} alt={plan.icon.alt} className={styles.icon} />
        <div className={styles.planContent}>
          <RichText
            render={plan.bullets.richText}
            serializeHyperlink={GatsbyLink}
          />
        </div>
        <div className={styles.planPrice}>
          <h4 className={styles.startingAt}>Starting at</h4>
          <h4 className={styles.price}>{`$${plan.price.text}/mo`}</h4>
        </div>
      </div>
    )
  })

  const sfPlans = (
    <div className={styles.planContainer}>
      <p className={styles.sfText}>
        Please contact our <Link to="/sanfrancisco">SanFrancisco</Link> branch
        for a customized quote.
      </p>
    </div>
  )

  return (
    <div className={styles.container} id="service_plans">
      <h2 className={styles.title}>{data.title.text}</h2>
      <div className={styles.description}>
        <RichText
          render={data.description.richText}
          serializeHyperlink={GatsbyLink}
        />
      </div>
      <div className={styles.selectContainer}>
        <h4
          className={plan === "Boston" && styles.selected}
          onClick={(e) => handleClick("Boston")}
        >
          Boston / New Orleans
        </h4>
        <h4
          className={plan === "SanFrancisco" ? styles.selected : undefined}
          onClick={(e) => handleClick("SanFrancisco")}
        >
          San Francisco
        </h4>
      </div>
      <div className={styles.planContainer}>
        {plan === "Boston" ? bostonPlans : sfPlans}
      </div>
      <div className={styles.bottomText}>
        <RichText render={data.bottom_text} serializeHyperlink={GatsbyLink} />
      </div>
    </div>
  )
}

export default Plans

import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import { BsChevronDown } from "react-icons/bs"
import * as styles from "../stylesheets/components/hero.module.scss"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicHome {
        data {
          blurb {
            richText
          }
          cta {
            text
          }
          main_title {
            text
          }
          image {
            alt
            url
          }
          services {
            richText
          }
          sub_title {
            text
          }
        }
      }
    }
  `).prismicHome.data

  const servicesLeft = data.services.richText.map((item, index, array) => {
    return (
      index < array.length / 2 && (
        <div className={styles.serviceList} key={index}>
          {item.text}
        </div>
      )
    )
  })

  const servicesRight = data.services.richText.map((item, index, array) => {
    return (
      index >= array.length / 2 && (
        <div className={styles.serviceList} key={index}>
          {item.text}
        </div>
      )
    )
  })

  return (
    <div className={styles.container}>
      <div
        className={styles.heroContainer}
        style={{ backgroundImage: `url(${data.image.url})` }}
        title={data.image.alt}
      >
        <div className={styles.titlesAndCTA}>
          <h1 className={styles.title}>{data.main_title.text}</h1>
          <h2 className={styles.subTitle}>{data.sub_title.text}</h2>
          <Link to="/free-consultation" className={styles.ctaContainer}>
            <button className={styles.cta}>{data.cta.text}</button>
          </Link>
        </div>
      </div>
      <div className={styles.blurbContainer}>
        <Link to="/free-consultation" className={styles.ctaMobileContainer}>
          <button className={styles.cta}>{data.cta.text}</button>
        </Link>
        <div className={styles.blurb}>
          <RichText render={data.blurb.richText} />
        </div>
      </div>
      <div className={styles.downContainer}>
        <BsChevronDown className={styles.downIcon} />
      </div>

      <div className={styles.servicesContainer}>
        <div className={styles.services}>{servicesLeft}</div>
        <div className={styles.services}>{servicesRight}</div>
      </div>
    </div>
  )
}

export default Hero
